<template>
<header class="content">
  <h1>About this Website</h1>
</header>
<section class="info">
  <p>
    The Hall of Challengers is an archive by date
    of the League of Legends Challenger roster.
    The website is updated throughout the day when official rosters
    are updated by Riot.
  </p>
  <h2>
    Contact
  </h2>
  <p>
    You can contact the site administrator
    via email at hoc at hallofchallengers dot com.
  </p>
</section>
</template>
